
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiFactoryOrderList } from '@/api/order'
import { apiShopLists } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import LsPagination from '@/components/ls-pagination.vue'
import elImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        elImageViewer,
        DatePicker,
        ExportData
    }
})
export default class plantOrder extends Vue {
    apiFactoryOrderList = apiFactoryOrderList

    pager: any = new RequestPaging()
    // 保存请求数据
    searchObj: any = {
        sid: '',
        start_time: '',
        end_time: ''
    }

    shopList: any = []

    timestampToYYMMDDHHMMSS(timestamp: number) {
        let date = new Date(timestamp * 1000) // 将时间戳转换为毫秒
        let year = date.getFullYear()
        let month = ('0' + (date.getMonth() + 1)).slice(-2)
        let day = ('0' + date.getDate()).slice(-2)
        let hours = ('0' + date.getHours()).slice(-2)
        let minutes = ('0' + date.getMinutes()).slice(-2)
        let seconds = ('0' + date.getSeconds()).slice(-2)

        let formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
        return formattedDate
    }
    setRowClassName(row: any) {
        return row.row.plan_name === null ? 'custom-row' : 'custom-row'
    }
    processInput(input: any) {
        if (!input) return false
        if (input.includes('},{')) {
            try {
                let parseItem = JSON.parse(`[${input}]`)
                let zhCnArr = parseItem.map((v: any) => {
                    return v.zh_cn
                })
                return zhCnArr.join(',')
            } catch (e) {
                return input
            }
        } else if (input.includes('{') && input.includes('}')) {
            try {
                let parseItem = JSON.parse(input)
                return parseItem.zh_cn ? parseItem.zh_cn : parseItem
            } catch (e) {
                return input
            }
        } else {
            return input
        }
    }

    setLogistics(val: string) {
        if (val) {
            let obj = JSON.parse(val)
            let arr: any = []
            Object.keys(obj).forEach(element => {
                arr.push(`${element}：${obj[element]}`)
            })
            return arr.join(',')
        } else {
            return ''
        }
    }

    showTimeFormatt(diffTime: any, index: any) {
        if (diffTime === null || diffTime === 0 || diffTime === '' || diffTime === undefined) return false
        let msg = ''
        if (diffTime <= 0) {
            // 已过期
            diffTime = Math.abs(diffTime)
            msg = '已超时:'
        } else {
            msg = '距离到期:'
        }
        const totalSeconds = Math.floor(diffTime / 1000)
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60
        return `${msg}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`
    }

    getOrderList(page?: number) {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiFactoryOrderList,
                params: {
                    ...this.searchObj
                }
            })
            .then((res: any) => {})
    }

    getShopList() {
        apiShopLists({ page_no: 1, page_size: 999 }).then((res: any) => {
            this.shopList = res.lists
        })
    }

    created() {
        this.getShopList()
        this.getOrderList()
    }
}
